export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const UPDATE_METADATA = "UPDATE_METADATA";
export const UPDATE_SHIPPING = "UPDATE_SHIPPING";
export const UPDATE_PINCODE_LIST = "UPDTAE_PINCODE_LIST";
export const UPDATE_CITY_LIST = "UPDTAE_CITY_LIST";
export const RESET_CART = "RESET_CART";
export const UPDATE_CART_VARIANTS = "UPDATE_CART_VARIANTS";
export const UPDATE_ADDONS = "UPDATE_ADDONS";
export const MATCH_ADDONS = "MATCH_ADDONS";
export const UPDATE_SAVED_ADDRESS = "UPDATE_SAVED_ADDRESS";
export const UPDATE_SELECTED_ADDRESS_INDEX = "UPDATE_SELECTED_ADDRESS_INDEX";
export const UPDATE_RECIEVER_DATA = "UPDATE_RECIEVER_DATA";
export const UPDATE_REFFER = "UPDATE_REFFER";
export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const UPDATE_LOYALTY = "UPDATE_LOYALTY";
export const UPDATE_TOTAL_LOYALTY = "UPDATE_TOTAL_LOYALTY";
export const UPDATE_DELIVERY_TYPE = "UPDATE_DELIVERY_TYPE";
export const UPDATE_DELIVERY_DATE = "UPDATE_DELIVERY_DATE";
export const UPDATE_TRANSACTION_ID = "UPDATE_TRANSACTION_ID";
export const CHANGE_AUTH_TOKEN = "CHANGE_AUTH_TOKEN";
export const CHANGE_AUTH_STATUS = "CHANGE_AUTH_STATUS";
export const CHANGE_USER_PROFILE = "CHANGE_USER_PROFILE";
export const UPDATE_ADV_DETAILS = "UPDATE_ADV_DETAILS";
export const UPDATE_PHONE_PE = "UPDATE_PHONE_PE";
export const UPDATE_TAB_VALUE = "UPDATE_TAB_VALUE";
export const UPDATE_CATEGORY_DATA = "UPDATE_CATEGORY_DATA";
export const UPDATE_PRODUCT_DATA = "UPDATE_PRODUCT_DATA";
export const UPDATE_VALIDATED_CART_RESPONSE = "UPDATE_VALIDATED_CART_RESPONSE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
