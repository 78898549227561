const ApiSuffix = {
  // logout: "/api/logout/",
  getCityList: "/api/get-city-list/",
  register: "/api/register-user/",
  generateOTP: "/api/send-otp/?username=",
  verifyOtp: "/api/verify-otp/?username=",
  getUserProfile: "/api/my-profile/",
  getMenuPageData: "/api/get-menu-page-data/menu/",
  getCityMenu: "/api/get-menu/",
  getStoreCatalogue: "/api/get-store-categories/",
  getCategoryData: "/api/get-products/",
  // getCategoryData: "/api/get-store-menu/",
  getProductDetails: "/api/get-product-info/?displayURL=",
  getAddons: "/api/get-add-ons/?id=",
  validateCart: "/api/validate-cart/",
  getAddressInfo: "/api/get-saved-address/?cityID=",
  addUserAddress: "/api/create-new-address/",
  calculateShipping: "/api/get-shipping-charges/",
  applyCoupon: "/api/apply-coupon/",
  orderRegistration: "/api/register-order/",
  deleteUserAddress: "/api/delete-address/?id=",
  getOrderDetail: "/api/order-details/",
  getHomePageData: "/api/home-page-data/",
  blogGeneric: "/api/blog/",
  getBlogData: "/api/get-blog-data/",
  getContactData: "/api/get-contact-data/",
  registerReferral: "/api/referral-register/",
  getStoreBills: "/api/get-pos-bills/",
  getBillDetails: "/api/get-bill-details/",

  /**Unused */
  searchProduct: "/api/search-products/?search=",
  getBannerData: "/home-page-data/",
  getCategoryDetails: "/get-products/",
  getPolicyData: "/get-policy-data/",
  customOrderRegistration: "/api/custom-order-registration/",
  getCatalogueData: "/get-catalogue/",

  // getCityStores: "/get-stores-locations/",
};

export default ApiSuffix;
