import {
  ADD_ITEM,
  REMOVE_ITEM,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  UPDATE_METADATA,
  UPDATE_SHIPPING,
  UPDATE_PINCODE_LIST,
  UPDATE_CITY_LIST,
  RESET_CART,
  UPDATE_ADDONS,
  MATCH_ADDONS,
  UPDATE_SAVED_ADDRESS,
  UPDATE_SELECTED_ADDRESS_INDEX,
  UPDATE_RECIEVER_DATA,
  UPDATE_PROMOTION,
  UPDATE_TOTAL_LOYALTY,
  UPDATE_LOYALTY,
  UPDATE_DELIVERY_TYPE,
  UPDATE_DELIVERY_DATE,
  UPDATE_TRANSACTION_ID,
  UPDATE_REFFER,
  UPDATE_CART_VARIANTS,
} from "../actionTypes";

const initialState = {
  productTotal: 0,
  cartShipping: 0,
  cartTotal: 0,
  savedAddress: [],
  selectedAddIndex: 0,
  mobileNumber: "",
  promoCodeApplied: "",
  loyaltyPoints: 0,
  promoAmount: 0,
  totalLoyaltyCashback: 0,
  products: [],
  deliveryType: "HD",
  deliveryDate: null,
  txnid: "",
  recieverName: "",
  refferName: "",
  customAmount: 0,
  addons: [],
  pincodeList: [],
  cityList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PINCODE_LIST: {
      const { pincodes } = action.payload;
      return {
        ...state,
        pincodeList: pincodes,
      };
    }
    case UPDATE_CITY_LIST: {
      const { cities } = action.payload;
      return {
        ...state,
        cityList: cities,
      };
    }
    case ADD_ITEM: {
      const { product } = action.payload;
      return {
        ...state,
        products: [...state.products, product],
      };
    }
    case REMOVE_ITEM: {
      const { index } = action.payload;
      let locCart = { ...state };
      locCart.products.splice(index, 1);
      return {
        ...state,
        products: locCart.products,
      };
    }
    case UPDATE_METADATA: {
      let productTotal = 0;
      let locCart = { ...state };
      locCart.products.forEach((product) => {
        productTotal =
          productTotal +
          Math.ceil(
            (100 - Number(product.selectedVariant.discount)) *
              0.01 *
              Number(product.selectedVariant.price) *
              Number(product.quantity)
          );
      });
      return {
        ...state,
        productTotal: productTotal,
      };
    }
    case UPDATE_SHIPPING: {
      const { shipping } = action.payload;
      let cartTotal = 0;
      let locState = { ...state };
      cartTotal = Number(locState.productTotal) + Number(shipping);
      return {
        ...state,
        cartShipping: shipping,
        cartTotal: cartTotal,
      };
    }
    case INCREASE_QUANTITY: {
      const { index } = action.payload;
      let locCart = { ...state };
      locCart.products[index].quantity++;
      return {
        ...state,
        products: locCart.products,
      };
    }
    case DECREASE_QUANTITY: {
      const { index } = action.payload;
      let locCart = { ...state };
      locCart.products[index].quantity--;
      return {
        ...state,
        products: locCart.products,
      };
    }
    case UPDATE_CART_VARIANTS: {
      const { cartList } = action.payload;
      let productTotal = 0;
      let locCart = { ...state };
      locCart.products.forEach((product) => {
        let variant = cartList.filter(
          (item) => item.productDisplayURL === product.product.productDisplayURL
        );
        if (variant.length === 1) {
          product.selectedVariant = variant[0].selectedVariant;
          productTotal =
            productTotal +
            Math.ceil(
              (100 - Number(product.selectedVariant.discount)) *
                0.01 *
                Number(product.selectedVariant.price) *
                Number(product.quantity)
            );
        } else {
          productTotal =
            productTotal +
            Math.ceil(
              (100 - Number(product.selectedVariant.discount)) *
                0.01 *
                Number(product.selectedVariant.price) *
                Number(product.quantity)
            );
        }
      });
      localStorage.setItem("products", JSON.stringify(locCart.products));
      return {
        ...state,
        products: locCart.products,
        productTotal: productTotal,
      };
    }
    case RESET_CART: {
      return {
        ...state,
        productTotal: 0,
        cartShipping: 0,
        cartTotal: 0,
        products: [],
      };
    }
    case UPDATE_ADDONS: {
      const { addons } = action.payload;
      return {
        ...state,
        addons: addons,
      };
    }
    case MATCH_ADDONS: {
      let locState = { ...state };
      let cart = locState.products;
      let _addons = locState.addons;
      _addons.map(
        (item, index) =>
          (item.added = cart.find((cartItem) => {
            return cartItem.product.productCode === item.code;
          })
            ? true
            : false)
      );
      return {
        ...state,
        addons: _addons,
      };
    }
    case UPDATE_SAVED_ADDRESS: {
      const { address } = action.payload;
      return {
        ...state,
        savedAddress: address,
      };
    }
    case UPDATE_SELECTED_ADDRESS_INDEX: {
      const { index } = action.payload;
      return {
        ...state,
        selectedAddIndex: index,
      };
    }
    case UPDATE_REFFER: {
      const { reffer, amount } = action.payload;
      return {
        ...state,
        refferName: reffer,
        customAmount: amount,
      };
    }
    case UPDATE_RECIEVER_DATA: {
      const { mobNo, name } = action.payload;
      return {
        ...state,
        mobileNumber: mobNo,
        recieverName: name,
      };
    }
    case UPDATE_PROMOTION: {
      const { promoCode, promoAmount } = action.payload;
      return {
        ...state,
        promoCodeApplied: promoCode,
        promoAmount: promoAmount,
      };
    }
    case UPDATE_TOTAL_LOYALTY: {
      const { points } = action.payload;
      return {
        ...state,
        totalLoyaltyCashback: points,
      };
    }
    case UPDATE_LOYALTY: {
      const { points, promoAmount } = action.payload;
      return {
        ...state,
        totalLoyaltyCashback: points,
        promoAmount: promoAmount,
        loyaltyPoints: promoAmount,
      };
    }
    case UPDATE_DELIVERY_TYPE: {
      const { delType } = action.payload;
      return {
        ...state,
        deliveryType: delType,
      };
    }
    case UPDATE_DELIVERY_DATE: {
      const { delDate } = action.payload;
      return {
        ...state,
        deliveryDate: delDate,
      };
    }
    case UPDATE_TRANSACTION_ID: {
      const { transId } = action.payload;
      return {
        ...state,
        txnid: transId,
      };
    }
    default:
      return state;
  }
}
