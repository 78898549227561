var BaseUrl;
switch (process.env.NODE_ENV) {
  case "development":
    BaseUrl = {
      // dev: "https://bakeonecom.pythonanywhere.com",
      dev: "https://backend.bakeone.in",
    };
    break;
  case "production":
    BaseUrl = {
      dev: "https://backend.bakeone.in",
    };
    break;
  default:
    BaseUrl = {
      dev: "https://backend.bakeone.in",
    };
    break;
}

export default BaseUrl;
